<template>
  <div>
    <b-modal v-model="modal.discountDetails" :title="$t('dashboard.discounts.discountDetails')" content-class="card card-ecart form-rounded-inputs" 
      header-class="card-header" size="lg" @hidden="fnResetDiscountDetails()" centered>
      <DiscountsDetails />
      <template v-slot:modal-footer>
        <p class="text-right">
          <button class="btn" v-text="$t('general.button.close')" @click="fnResetDiscountDetails()"></button>
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  import DiscountsDetails from "./discounts-details";

  export default {
    components:{
      DiscountsDetails,
    },
    computed: {
      ...mapState('discounts', ['modal', 'storesList']),
    },
    methods: {
      ...mapMutations('discounts', ['changeModal']),
      ...mapActions('discounts', ['fnApiGetStores', 'fnApiGetCarriers', 'fnApiGetDiscountsList']),
      fnResetDiscountDetails() {
        this.changeModal({
          discountDetails: false
        });
      }
    }
  }
</script>