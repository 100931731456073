<template>
  <div>
    <div class="row justify-content-between ">
      <div class="col-12 col-md-6">
        <p class="m-0">
          <strong v-text="$t('general.form.name') + ': '"></strong>
          <span v-text=" discountDetails.name "></span>
        </p>
      </div>
      <div class="col-12 col-md-auto ">
        <p class="m-0">
          <strong v-text="$t('tables.status') + ': '"></strong>
          <strong :class="discountDetails.active ? 'text-success': 'text-muted' "
            v-text=" discountDetails.active ? $t('general.online'): $t('general.offline') "></strong>
        </p>
      </div>
      <div class="col-12">
        <p class="m-0">
          <strong v-text="$t('general.form.storeName') + ': '"></strong>
          <span v-text=" fnGlobalGetStoreName(storesList,discountDetails.store_id) "></span>
        </p>
      </div>
    </div>
    <hr>

    <div class="" v-if=" discountDetails.date_from ">
      <div class="row justify-content-between ">
        <div class="col-12 col-md-auto ">
          <p class="m-0">
            <strong class="f-w-600" v-text="$t('general.form.initialDate') + ': ' "></strong>
            <span v-text=" $moment( discountDetails.date_from ).format(CONST.DATE_FORMATS.DD_MM_YYYY) "></span>
          </p>
        </div>
        <div class="col-12 col-md-auto ">
          <p class="m-0">
            <strong class="f-w-600" v-text="$t('general.form.dateLimit') + ': '"></strong>
            <span v-text=" $moment( discountDetails.date_to ).format(CONST.DATE_FORMATS.DD_MM_YYYY) "></span>
          </p>
        </div>
      </div>
    </div>

    <hr v-if=" discountDetails.date_from ">

    <div class="row align-items-end justify-content-between ">

      <div class="col-12 col-md-auto">
        <p class="m-0">
          <strong class="f-w-600" v-text="$t('general.form.availability') + ': '"></strong>
          <span class="text-success" v-text=" $t('general.form.unlimited') "
            v-if=" (discountDetails.unlimit) || (discountDetails.available == -1) "></span>
          <span v-text=" discountDetails.available " v-else></span>
        </p>
      </div>
      <div class="col-12 col-md-auto ">
        <p class="m-0">
          <strong class="f-w-600" v-text="$t('tables.type') + ': '"></strong>
          <span v-text=" $t('dashboard.discounts.typesList.' + discountDetails.type ) "></span>
        </p>
      </div>
      <div class="col-12 col-md-auto ">
        <p class="m-0">
          <strong class="f-w-600" v-text="$t('tables.amount') + ': '"></strong>
          <span v-text=" discountDetails.amount + '%' " v-if="discountDetails.is_percent"></span>
          <span v-text=" fnFormatPrice( discountDetails.amount , discountDetails.currency ) " v-else></span>
        </p>
      </div>
      <div class="col-12 col-md-auto" v-if="discountDetails.min_amount > -1">
        <p class="m-0">
          <strong class="f-w-600" v-text="  $t('general.form.minPurchase') +': ' "></strong>
          <span v-text=" fnFormatPrice( discountDetails.min_amount , discountDetails.currency ) "></span>
        </p>
      </div>
      <div class="col-12 col-md-auto" v-if="discountDetails.amount_cap > -1 ">
        <p class="m-0">
          <strong class="f-w-600" v-text="  $t('general.form.max') +': ' "></strong>
          <span v-text=" fnFormatPrice( discountDetails.amount_cap , discountDetails.currency ) "></span>
        </p>
      </div>
    </div>

    <hr>

    <div class="row justify-content-center" v-if=" discountDetails.type == 'coupons' ">
      <div class="col-12 col-md-6">
        <div class="card bg-light my-2">
        <p class=" text-center py-4 m-0 f-w-700 f-18">
          <strong class="f-w-700" v-text="$t('general.form.coupon') + ': '"></strong>
          <strong class="text-success" v-text="discountDetails.coupon"></strong>
        </p>
      </div>
      </div>
    </div>
    
    <div v-if=" discountDetails.type == 'products' ">
      <table class="table table-bordered" v-if=" discountDetails.products.length > 0 ">
        <thead>
          <tr>
            <th v-text=" $t('general.form.includedProducts') ">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for=" (product,index) in discountDetails.productsList " :key="index">
            <td>
              <div class="row align-items-center">
                <div class="col-2 col-md-auto">
                  <img v-image="product.imageUrl" alt="" class="img-cover-40">
                </div>
                <div class="col-10 col-md ">
                  <p class="mb-0">
                    <span v-text=" product.name  ? product.name : product.id  "></span>
                  </p>
                  <p class="mb-0">
                    <strong v-text=" $t('general.form.price') + ': ' "></strong>
                    <span
                      :class=" (( discountDetails.amount > 0 && discountDetails.amount <= 100)) ? 'text-line-through text-muted' : ''  "
                      v-text="fnFormatPrice(  product.price ,  product.currency )  "></span>
                    <span v-show="( discountDetails.amount > 0 && discountDetails.amount <= 100)"
                      class="animated fadeIn">
                      <span v-show="( discountDetails.amount > 0 && discountDetails.amount <= 100)"> - </span>
                      <strong class="text-success"
                        v-text=" fnFormatPrice( ( product.price - ( discountDetails.amount * product.price / 100  ))  , product.currency ) "></strong>
                    </span>
                  </p>
                </div>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>
          <strong v-text="$t('general.form.includedProducts') + ': ' "></strong>
          <strong class="text-success" v-text="$t('general.form.all')"></strong>
        </p>
      </div>

      <table class="table table-bordered " v-if=" discountDetails.exclude_products.length > 0 ">
        <thead>
          <tr>
            <th v-text=" $t('general.form.excludedProducts') ">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for=" (product,index) in discountDetails.productsListExcluded " :key="index">
            <td>
              <div class="row align-items-center">
                <div class="col-2 col-md-auto">
                  <img v-image="product.imageUrl" alt="" class="img-cover-40">
                </div>
                <div class="col-10 col-md ">
                  <p class="mb-0">
                    <span v-text=" product.name  ? product.name : product.id  "></span>
                  </p>
                  <p class="mb-0">
                    <strong v-text=" $t('general.form.price') + ': ' "></strong>
                    <span v-text="fnFormatPrice(  product.price ,  product.currency ) "></span>
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <table class="table table-bordered" v-if=" discountDetails.type == 'shipping' ">
      <thead>
        <tr>
          <th v-text="$t('general.form.carriers')"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for=" (carrier,index) in getCarriers " :key="index">
          <td>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-auto">
                    <img v-image="carrier.image" class="img-contain-50 p-1" alt="">
                  </div>
                  <div class="col">
                    <p class="m-0">
                      <strong class="text-capitalize" v-text=" carrier.name "> </strong> <br>
                      <span v-text="$t('general.form.services') + ':'"> </span>
                    </p>
                    <ul class="pl-3">
                      <li v-for=" (service,indexService) in carrier.services " :key="indexService" v-show="service.active">
                        <div class="row align-items-center">
                          <div class="col-8">
                            <p class="m-0">
                              <span
                                v-text=" $te('general.carriers.services.' + service.name) ? $t('general.carriers.services.' + service.name) : service.name  "></span>
                              <span class="text-muted m-0" v-if="$te('general.carriers.services.' + service.name)">
                                <small> -
                                  <span
                                    v-text=" $t('general.carriers.services.' + service.name + '_description') "></span>
                                </small>
                              </span>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  export default {
    computed: {
      ...mapState('discounts', ['modal', 'storesList', 'discountDetails', 'carriersList']),
      ...mapGetters('discounts', ['getCarriers']),
    },
    methods: {
      ...mapMutations('discounts', ['changeModal']),
      ...mapActions('discounts', ['fnApiGetStores', 'fnApiGetCarriers', 'fnApiGetDiscountsList']),

     
      fnResetDiscountDetails() {
        this.changeModal({
          discountDetails: false
        });
      }
    }
  }
</script>