<template>
  <div class="h-100-vh form-rounded-inputs">
    <p class="text-right">
      <b-dropdown class="rounded-pill" variant="success" :text="$t('general.button.create')" right>
        <b-dropdown-item @click="changeModal({coupons:true})">
          <span v-text="$t('dashboard.discounts.types.newCoupons')"></span>
        </b-dropdown-item>
        <b-dropdown-item @click="changeModal({shipping:true})" class="d-none">
          <span v-text="$t('dashboard.discounts.types.shipmentsDiscount')"></span>
        </b-dropdown-item>
        <b-dropdown-item @click="changeModal({products:true})">
          <span v-text="$t('dashboard.discounts.types.productsDiscount')"></span>
        </b-dropdown-item>
      </b-dropdown>
    </p>

    <div class=" ">
      <div v-show=" discountsList.length > 0 ">
        <table class="table ecart-table table-striped d-md-none ">
          <thead class="">
            <tr class="text-left">
              <th v-text="$t('dashboard.discounts.title')"></th>
            </tr>
          </thead>
          <tbody>
            <tr :class="discount.active ? '' : 'item-offline' " v-for=" (discount, index) in discountsList "
              :key="index">
              <td>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-8 col-sm-9">
                        <p class="m-0 text-truncate">
                          <strong v-text="$t('general.form.name') + ': '  "></strong>
                          <router-link to="" class="text-success">
                            <strong @click="fnDiscountDetails(index)"
                              v-text=" discount.name ? discount.name : discount.id  "></strong>
                          </router-link>
                        </p>
                      </div>
                      <div class="col-4 col-sm-3 text-right">
                        <b-dropdown variant="success" size="sm" right :text="$t('general.actions')">
                          <b-dropdown-item @click="fnDiscountDetails(index)">
                            <p>
                              <i class="fab fa-sm fa-wpforms mr-2"></i>
                              <span v-text=" $t('general.details') "></span>
                            </p>
                          </b-dropdown-item>
                          <b-dropdown-item @click="fnUpdateDiscount(index)">
                            <p>
                              <i class="fa fa-sm fa-pencil-alt mr-2"></i>
                              <span v-text=" $t('general.button.edit') "></span>
                            </p>
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item @click="fnApiStatusDiscount(index)">
                            <p>
                              <i class="fa fa-sm fa-power-off mr-2"></i>
                              <span
                                v-text=" !discount.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                            </p>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>

                  </div>
                  <div class="col-12 col-sm-6 col-md-4 ">
                    <p>
                      <strong class="f-w-500" v-text=" $t('tables.type') + ': ' "></strong>
                      <span v-text=" $t('general.' + discount.type)"> </span>
                    </p>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <p>
                      <strong class="f-w-500" v-text=" $t('general.form.discount') + ': ' "></strong>
                      <span v-text=" discount.amount + '%' " v-if="discount.is_percent">
                      </span>
                      <span v-else v-text=" fnFormatPrice(discount.amount , discount.currency) ">
                      </span>
                    </p>
                  </div>

                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="table ecart-table table-striped text-center d-none d-md-table">
          <thead>
            <tr>
              <th class="text-left" v-text="$t('dashboard.discounts.title')"> </th>
              <th v-text="$t('tables.type')"></th>
              <th v-text="$t('tables.discount')"></th>
              <th v-text="$t('general.form.availability')"></th>
              <th v-text="$t('general.actions')"> </th>
            </tr>
          </thead>
          <tbody>
            <tr :class="discount.active ? '' : 'item-offline' " v-for=" (discount, index) in discountsList "
              :key="index">

              <td class="text-left">
                <p class="m-0">
                  <button class="btn p-0" @click="fnDiscountDetails(index)">
                    <strong class="text-success" v-text=" discount.name ? discount.name : discount.id  ">
                    </strong>
                  </button>
                </p>
                <p class="m-0">
                  <!-- <i class="fa fa-store mr-1 "></i> -->
                  <span v-text="$t('general.form.store') + ': ' "></span>
                  <span v-text=" fnGlobalGetStoreName( storesList , discount.store_id )"></span>
                </p>
              </td>
              <td>
                <p v-text=" $t('general.' + discount.type)"></p>
              </td>

              <td>
                <p v-if="discount.is_percent">
                  <span v-text=" discount.amount + '%' "></span>
                </p>
                <p v-else>
                  <span v-text=" fnFormatPrice( discount.amount , discount.currency )   "></span>
                </p>
              </td>
              <td>
                <p v-if=" (discount.unlimit) || (discount.available == -1) ">
                  <span v-text=" $t('general.form.unlimited')  "></span>
                </p>
                <p v-else>
                  <span v-text=" discount.available "></span>
                </p>
              </td>
              <td>
                <b-dropdown variant="success" size="sm" right no-caret>
                  <template v-slot:button-content class="m-0">
                    <i class="fa fa-ellipsis-h fa-lg"></i>
                  </template>
                  <b-dropdown-item @click="fnDiscountDetails(index)">
                    <p>
                      <i class="fab fa-sm fa-wpforms mr-2"></i>
                      <span v-text=" $t('general.details') "></span>
                    </p>
                  </b-dropdown-item>
                  <b-dropdown-item @click="fnUpdateDiscount(index)">
                    <p>
                      <i class="fa fa-sm fa-pencil-alt mr-2"></i>
                      <span v-text=" $t('general.button.edit') "></span>
                    </p>
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="fnApiStatusDiscount(index)">
                    <p>
                      <i class="fa fa-sm fa-power-off mr-2"></i>
                      <span
                        v-text=" !discount.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                    </p>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <no-data dataMessage="You still don't have discounts, add one and sell more" v-show=" discountsList.length == 0 "
        :isLoading="loading.discountsList" :showBtn="false"></no-data>
    </div>

    <DiscountsCoupons />
    <DiscountsShipping />
    <DiscountsProducts />
    <DiscountsDetailsModal />
  </div>
</template>

<script>
  import DiscountsCoupons from "./components/discounts-coupons";
  import DiscountsShipping from "./components/discounts-shipping";
  import DiscountsProducts from "./components/discounts-products";
  import DiscountsDetailsModal from "./components/discounts-details-modal";



  import {
    EcDiscountModelHotSale,
  } from "@/plugins/beans.js"


  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  export default {
    components: {
      DiscountsCoupons,
      DiscountsShipping,
      DiscountsProducts,
      DiscountsDetailsModal,
    },
    data() {
      return ({
        isLoading: true,
      })
    },
    computed: {
      ...mapState('discounts', ['discountsList', 'loading', 'storesList', 'discountDetails']),
    },
    methods: {
      ...mapMutations('discounts', ['changeModal', 'setDiscountDetails', 'setDiscount', 'setDiscountInformation']),
      ...mapActions('discounts', ['fnApiGetStores', 'fnApiGetCarriers', 'fnApiGetDiscountsList',
        'fnApiGetDiscountProducts'
      ]),

      async fnUpdateDiscount(index) {
        await this.fnApiGetDiscountProducts(index);
        this.setDiscount(index);
      },

      async fnDiscountDetails(index) {
        await this.fnApiGetDiscountProducts(index);
        this.setDiscountDetails({
          ...this.discountsList[index]
        });
        this.changeModal({
          discountDetails: true
        });
      },

      async fnApiDeleteDiscount(index) {
        await axios.delete('discounts/' + this.discountsList[index].id).then(response => {
          this.discountsList.splice(index, 1);
          this.$toasted.global.infoMessage(this.$t('toasted.info.discountDeleted'));
        }).catch(error => {});
      },

      async fnApiStatusDiscount(index) {
        await axios.put(`discounts/status/` + this.discountsList[index].id, {
          active: !this.discountsList[index].active
        }).then(response => {
          this.$toasted.global.infoMessage(this.$t('toasted.info.discountUpdated'));
          this.discountsList[index].active = !this.discountsList[index].active;
        }).catch(error => {});
      },

    },
    beforeMount() {
      if (this.$route.query.hot_sale) {
        this.changeModal({
          products: true
        });
        this.setDiscountInformation( EcDiscountModelHotSale() );
      }
    },
    mounted() {
      this.fnApiGetDiscountsList();
      this.fnApiGetStores();
      this.fnApiGetCarriers();
    }
  }
</script>